<template>
  <div class="not-access-dialog">
      <div class="not-access-dialog-content-body">

        <div class="not-access-dialog-actions">
          <button @click="$router.push('/login')" class="generic-btn">
            {{ $t('commons.login') }}
          </button>
          <button @click="$router.push('/register')" class="generic-btn">
            {{ $t('commons.registration') }}
          </button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.not-access-dialog {

  .not-access-dialog-content-body {
    width: 100%;

    h1 {
      color: white;
      max-width: 506px;
      text-align: center;
      margin: auto;
      margin-bottom: 28px;
      margin-top: 31px;
    }

    .not-access-dialog-actions {
      display: flex;
      flex-direction: row;
      align-items: center;

      .generic-btn {
        margin: 10px auto;
        width: 271px;
      }
    }
  }
}

@media screen and (max-width: 500px) {

  .not-access-dialog-content-body {
    h1 {
      margin: 20px 10px !important;
    }
  }

  .not-access-dialog-actions {
    flex-wrap: wrap;
    justify-content: center !important;

    button {
      margin-bottom: 10px;
    }
  }
}
</style>
